<template>

  <div class="pageContent">
      <div class="header">

          <el-menu
          mode="horizontal"
          class="el-menu-header"
          >
              <el-menu-item index="">
                  <img :src="require('@/assets/titlelog.jpg')" :alt="得圣" style="width:125px; height: 57px;" />
              </el-menu-item>
              <el-menu-item index="1">首页</el-menu-item>
              <el-menu-item index="2">产品应用</el-menu-item>
              <el-menu-item index="4">技术支持</el-menu-item>
              <el-menu-item index="5">关于我们</el-menu-item>
              <el-menu-item index="6">
                <el-button class="btn-class" @click="dialogVisible = true" >下载手机APP</el-button>
              </el-menu-item>
          </el-menu>
      </div>
      <div class="main-content">
          <div class="cartoon-character">  
              <div class="image-container1">

                  <el-row :gutter="20">
                      <el-col :span="12">
                          <div>
                              <h2>
                                  网络无界 管理有方
                              </h2>
                              <p>
                                  得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的
                          无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各
                          种环境中享有安心和便利，保障用户隐私安全。
                              </p>
                          </div>
                      </el-col>

                      <el-col :span="12">

                          <div>
                              <img :src="require('@/assets/oth2.png')" />
                          </div>

                      </el-col>
                  </el-row>

                   
              </div>
          
          </div>

          
          <div class="cartoon-character">
              <div class="image-container2">

                   <!-- 这里可以放置一个SVG或图片来显示卡通人物 -->  
              <div style="display: flex; align-items: center; justify-content: space-between;">
                  <img :src="require('@/assets/oth3.png')" style="width: 10px; height: 10px;" />
                  <h3 style="margin-left: 20px; margin-right: 20px;">得圣防窥魔盒</h3>
                  <img :src="require('@/assets/oth3.png')" style="width: 10px; height: 10px;" />
              </div>

              <div style="size: 14px;">网络的安全卫士</div>

              <el-row :gutter="20">
                  <el-col :span="8">
                  <div>
                      <h5>高效的无线检测技术</h5>
                      <el-text size="small">得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的
                  无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各
                  种环境中享有安心和便利，保障用户隐私安全。</el-text>
                  </div>
                  </el-col>
                  <el-col :span="8">
                  <div>
                      <h5>高效的无线检测技术</h5>
                      <el-text size="small">得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的
                  无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各
                  种环境中享有安心和便利，保障用户隐私安全。</el-text>
                  </div>
                  </el-col>
                  <el-col :span="8">
                  <div>
                      <h5>高效的无线检测技术</h5>
                      <el-text size="small">得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的
                  无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各
                  种环境中享有安心和便利，保障用户隐私安全。</el-text>
                  </div>
                  </el-col>
              </el-row>

              </div>
          </div>

          <div class="cartoon-character">

              <div class="image-container3">

              <!-- 这里可以放置一个SVG或图片来显示卡通人物 -->  
              <div style="display: flex; align-items: center; justify-content: space-between;">
              <img :src="require('@/assets/oth3.png')" :alt="123" style="width: 10px; height: 10px;" />
              <h3 style="margin-left: 20px; margin-right: 20px;">发现更多功能</h3>
              <img :src="require('@/assets/oth3.png')" :alt="123" style="width: 10px; height: 10px;" />
              </div>
              <div style="size: 14px;">无论何时何地都能管理你的网络</div>
              <el-row :gutter="20">
                  <el-col :span="8">
                  <div>
                      <h5>周边的WIFI扫描</h5>
                      <el-text size="small">
                      安全级别判断: 通过扫描周围WIFI 路由器和网络播报方式的发射源，
                      并判断安全级别

                      详细的信息展示: 提供绿、黄、红三色标识，辅以详细的信息如用户名(SSID),
                      信号强度和距离

                      发射源追踪: 支持追踪发射源，确保准确定位信号来源
                      </el-text>
                  </div>
                  </el-col>
                  <el-col :span="8">
                  <div>
                      <h5>终端信号跟踪</h5>
                      <el-text size="small">
                      设备信息显示：连接的WIFI网络，扫描终端设备显示数量、品牌、型号等信息。
                      设备管理功能：提供终端设备管理功能，包括接入网时间，登录次数等细节。
                      风险标记：标记未识别设备和危险信号，帮助用户核实和排查潜在风险。
                      </el-text>
                  </div>
                  </el-col>
                  <el-col :span="8">
                  <div>
                      <h5>主动信号告警</h5>
                      <el-text size="small">
                      动态功率图展示：提供直观的射频信号动态功率图，实时显示不同频带通道功率情况
                      异常信号发现: 发现异常信号兵提供场强告警，操作简便，可快速扫描周围环境的WIFI设备。

                      设备定位功能：自动展示监控设备并发出警告、高灵敏度，定位精度小于2米，快速获取终端设备位置。
                      </el-text>
                  </div>
                  </el-col>
              </el-row>

              </div>

          </div>

      </div>

      <div class="footer">
          <el-row :gutter="20">
              <el-col :span="12">
              <div class="description">  
                  <!-- 这里可以放置更多的描述性文本 -->  
                  <h1>联系我们</h1>
                  <p>地址：北京市丰台区高立庄616号新华国际中心D座221</p>
                  <p>电话： 400-106-6576</p>
                  <p>邮箱: postmaster@dstckj.com</p>
              </div>
              </el-col>
              <el-col :span="4">
              <el-divider  direction="vertical"  border-style="dashed" style="height: 200px; margin-left: 50px;" />
              </el-col>
              <el-col :span="8">
              <div class="description">  
                  <!-- 这里可以放置更多的描述性文本 -->  
                  <img :src="require('@/assets/wxgz.png')" style="width: 200px; height: 200px;">
              </div>

              </el-col>
          </el-row>
          
          <div class="copyright">  
              <p>Copyright © 北京东圣天成科技有限公司</p>  
          </div>  
      </div>

  </div>

  <el-dialog
    v-model="dialogVisible"
    title="二维码下载"
    width="250"
    :before-close="handleClose"
  >
  <img :src="require('@/assets/wxgz.png')" style="width: 200px; height: 200px;">
  </el-dialog>

</template>

<script setup lang="ts">

import { ref } from 'vue'

const dialogVisible = ref(false)


</script>

<style scoped>

.page-container {  
display: flex;  
flex-direction: column;  
align-items: center;  
margin: 0 auto;  
width: 100%; /* 根据实际需求调整宽度 */  
}  

.header {  
margin-top: 20px;  
text-align: center;
}  

.main-content {  
margin-top: 0px;  
text-align: center;  
}

.cartoon-character img {  
width: 300px; /* 根据卡通人物图片大小调整 */  
height: auto;  
}  

.footer {  
display: flex;  
flex-direction: column;  
align-items: center;  
margin-top: 20px;  
}  

.btn-class {
background-image: url('@/assets/btn_bg1.jpg');
background-size: cover; /* 背景图片覆盖整个按钮 */
background-position: center; /* 背景图片居中 */
border: none; /* 移除边框 */
color: white; /* 设置文字颜色 */
padding: 10px 20px; /* 根据需要设置内边距 */
}

.el-menu-header {
  justify-content: center;

}
.image-container1 {
position: relative; /* 为绝对定位的子元素提供参考 */
width: 100%;
height: 500px;
background: url('@/assets/conterbg.png');
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}

.image-container2 {
position: relative; /* 为绝对定位的子元素提供参考 */
width: 100%;
height: 500px;
background: url('@/assets/conterbg3.png');
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}

.image-container3 {
position: relative; /* 为绝对定位的子元素提供参考 */
width: 100%;
height: 500px;
background: url('@/assets/conterbg2.png');
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}

.text-left{
  width: 500px;
  height: 73px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 28px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-top: 80px;
}

.description p {  
text-align: justify;  
margin: 10px 0;  
}  

.copyright {  
font-size: 0.8em;  
}
</style>