import { createRouter, createWebHistory  } from 'vue-router'


import homePage from '@/views/home/homePage.vue'

const route = [
  {
    path: '/',
    name: 'HomePage',
    component: homePage
  }
]

const router = createRouter({
  history: createWebHistory (),
  routes: route
})

export default router
