import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pageContent" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "main-content" }
const _hoisted_5 = { class: "cartoon-character" }
const _hoisted_6 = { class: "image-container1" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "cartoon-character" }
const _hoisted_9 = { class: "image-container2" }
const _hoisted_10 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "cartoon-character" }
const _hoisted_14 = { class: "image-container3" }
const _hoisted_15 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "footer" }
const _hoisted_19 = { class: "description" }
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'homePage',
  setup(__props) {


const dialogVisible = ref(false)



return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_menu, {
          mode: "horizontal",
          class: "el-menu-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, { index: "" }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/assets/titlelog.jpg'),
                  alt: _ctx.得圣,
                  style: {"width":"125px","height":"57px"}
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("首页")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "2" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("产品应用")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "4" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("技术支持")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "5" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("关于我们")
              ])),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "btn-class",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (dialogVisible.value = true))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("下载手机APP")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("div", null, [
                      _createElementVNode("h2", null, " 网络无界 管理有方 "),
                      _createElementVNode("p", null, " 得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的 无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各 种环境中享有安心和便利，保障用户隐私安全。 ")
                    ], -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: require('@/assets/oth2.png')
                      }, null, 8, _hoisted_7)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("img", {
                src: require('@/assets/oth3.png'),
                style: {"width":"10px","height":"10px"}
              }, null, 8, _hoisted_11),
              _cache[8] || (_cache[8] = _createElementVNode("h3", { style: {"margin-left":"20px","margin-right":"20px"} }, "得圣防窥魔盒", -1)),
              _createElementVNode("img", {
                src: require('@/assets/oth3.png'),
                style: {"width":"10px","height":"10px"}
              }, null, 8, _hoisted_12)
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"size":"14px"} }, "网络的安全卫士", -1)),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[10] || (_cache[10] = _createElementVNode("h5", null, "高效的无线检测技术", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的 无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各 种环境中享有安心和便利，保障用户隐私安全。")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[12] || (_cache[12] = _createElementVNode("h5", null, "高效的无线检测技术", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的 无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各 种环境中享有安心和便利，保障用户隐私安全。")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[14] || (_cache[14] = _createElementVNode("h5", null, "高效的无线检测技术", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("得圣是东圣天成的一项重要创新成果，专为为个人、专为个人、家庭以及中小型企业的 无线网络环境安全使用和管理而设计。通过得圣防窥魔盒智能监控，我们确保用户在各 种环境中享有安心和便利，保障用户隐私安全。")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("img", {
                src: require('@/assets/oth3.png'),
                alt: 123,
                style: {"width":"10px","height":"10px"}
              }, null, 8, _hoisted_16),
              _cache[16] || (_cache[16] = _createElementVNode("h3", { style: {"margin-left":"20px","margin-right":"20px"} }, "发现更多功能", -1)),
              _createElementVNode("img", {
                src: require('@/assets/oth3.png'),
                alt: 123,
                style: {"width":"10px","height":"10px"}
              }, null, 8, _hoisted_17)
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("div", { style: {"size":"14px"} }, "无论何时何地都能管理你的网络", -1)),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[18] || (_cache[18] = _createElementVNode("h5", null, "周边的WIFI扫描", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" 安全级别判断: 通过扫描周围WIFI 路由器和网络播报方式的发射源， 并判断安全级别 详细的信息展示: 提供绿、黄、红三色标识，辅以详细的信息如用户名(SSID), 信号强度和距离 发射源追踪: 支持追踪发射源，确保准确定位信号来源 ")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[20] || (_cache[20] = _createElementVNode("h5", null, "终端信号跟踪", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode(" 设备信息显示：连接的WIFI网络，扫描终端设备显示数量、品牌、型号等信息。 设备管理功能：提供终端设备管理功能，包括接入网时间，登录次数等细节。 风险标记：标记未识别设备和危险信号，帮助用户核实和排查潜在风险。 ")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _cache[22] || (_cache[22] = _createElementVNode("h5", null, "主动信号告警", -1)),
                      _createVNode(_component_el_text, { size: "small" }, {
                        default: _withCtx(() => _cache[21] || (_cache[21] = [
                          _createTextVNode(" 动态功率图展示：提供直观的射频信号动态功率图，实时显示不同频带通道功率情况 异常信号发现: 发现异常信号兵提供场强告警，操作简便，可快速扫描周围环境的WIFI设备。 设备定位功能：自动展示监控设备并发出警告、高灵敏度，定位精度小于2米，快速获取终端设备位置。 ")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createElementVNode("div", { class: "description" }, [
                  _createElementVNode("h1", null, "联系我们"),
                  _createElementVNode("p", null, "地址：北京市丰台区高立庄616号新华国际中心D座221"),
                  _createElementVNode("p", null, "电话： 400-106-6576"),
                  _createElementVNode("p", null, "邮箱: postmaster@dstckj.com")
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_divider, {
                  direction: "vertical",
                  "border-style": "dashed",
                  style: {"height":"200px","margin-left":"50px"}
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("img", {
                    src: require('@/assets/wxgz.png'),
                    style: {"width":"200px","height":"200px"}
                  }, null, 8, _hoisted_20)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "copyright" }, [
          _createElementVNode("p", null, "Copyright © 北京东圣天成科技有限公司")
        ], -1))
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dialogVisible).value = $event)),
      title: "二维码下载",
      width: "250",
      "before-close": _ctx.handleClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('@/assets/wxgz.png'),
          style: {"width":"200px","height":"200px"}
        }, null, 8, _hoisted_21)
      ]),
      _: 1
    }, 8, ["modelValue", "before-close"])
  ], 64))
}
}

})